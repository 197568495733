import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import {
  FilterKey,
  FilterValuesObject,
  getInitialFilterKeysMap,
  getSingleSelectItem,
  ModelCardWithType,
  useFilterValues,
  useMergeFilterValues,
} from '@finn/ua-vehicle';
import { USPBannerData } from '@finn/ui-cosmic';
import { replaceJSRenderTemplate, slugify } from '@finn/ui-utils';
import get from 'lodash/get';

import { BaseCosmicObject, PLPPageData } from '~/types/cosmicModules';

type USPBannerTemplateData = {
  product_count: number;
  min_price: number;
  brand_list: string[];
  model_list: string[];
  cartype_list: string[];
};

export const getUSPBanner = async (
  pageData: PLPPageData,
  templateData: USPBannerTemplateData,
  campaignName: string
): Promise<string | USPBannerData> => {
  try {
    const uspBannerCosmicContent =
      pageData?.metadata?.usp_banners?.[`campaign_${campaignName}`]?.metadata;

    if (!uspBannerCosmicContent) {
      return '';
    }

    const variant = uspBannerCosmicContent.variant.key;
    const title = await replaceJSRenderTemplate(
      uspBannerCosmicContent.title,
      templateData
    );

    return {
      variant,
      title,
      paid_marketing_campaign: uspBannerCosmicContent.paid_marketing_campaign,
    };
  } catch (e) {
    return '';
  }
};

export const getPromoBannerHtml = (
  pageData: BaseCosmicObject<any>,
  isPdp: boolean,
  isMobile: boolean
) => {
  const bannerSuffix = isMobile ? 'mobile' : 'desktop';
  const path = `metadata.${
    isPdp ? 'content.' : ''
  }promo_banners.promo_${bannerSuffix}.content`;

  return get(pageData, path, '') as string;
};

export const getUSPBannerData = (
  filters: FilterValuesObject,
  totalResults: number,
  minPrice: number,
  initialFilterKeysMap: ReturnType<typeof getInitialFilterKeysMap> = {}
): USPBannerTemplateData => {
  const brandKey = getSingleSelectItem(filters, FilterKey.BRANDS);
  const brand = initialFilterKeysMap[slugify(brandKey)]?.displayedName || '';

  const modelKey = getSingleSelectItem(filters, FilterKey.MODELS);
  const model = initialFilterKeysMap[slugify(modelKey)]?.displayedName || '';

  const cartypeKey = getSingleSelectItem(filters, FilterKey.CAR_TYPES);
  const cartype =
    initialFilterKeysMap[slugify(cartypeKey)]?.displayedName || '';

  return {
    product_count: totalResults,
    min_price: minPrice,
    brand_list: [brand],
    model_list: [model],
    cartype_list: [cartype],
  };
};

// small helper hook used for special way of enabling filtering by model
export const useHandleModelCardSelection = () => {
  const filterValues = useFilterValues();
  const mergeFilterValues = useMergeFilterValues();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  return ({ vehicle, ...card }: ModelCardWithType, position: number) => {
    mergeFilterValues({
      ...filterValues,
      brands: [card.brand],
      models: [card.model],
    });

    interactionTrackingEvent(TrackingEventName.PRODUCT_CARD_CLICKED, {
      position,
      productCardType: 'model_card',
      ...card,
    });
  };
};
