import { Breadcrumb } from '@finn/ua-auth';
import { CosmicObject, SeoHeaderData } from '@finn/ui-cosmic';
import { usePathname } from 'next/navigation';
import { createContext, ReactNode, useEffect, useRef } from 'react';

import { getForBusinessCookie, setForBusinessCookie } from '../../../helpers';
import { getUpdatedSeoHeader } from '../../content';
import { FilterKey } from '../../core';
import { GenericVehicleDetails } from '../../product-cards';
import { AvailableFilters } from '../hooks/useAvailableFilters';
import { useFilter } from '../hooks/useFilter';
import { useFiltersResponse } from '../hooks/useFilterResponse';
import { Filters } from '../hooks/useInitialFilterValues';
import { useFilterValues, useMergeFilterValues } from './lib';

export type FilterContextValue = {
  isNoIndexNoFollow: boolean;
  canonicalUrl: string;
  advisoryContent: CosmicObject | null;
  filterSEOHeader: SeoHeaderData;
  breadcrumbs: Breadcrumb[];
  title: string;
};

export const FilterContext = createContext<FilterContextValue>(
  {} as FilterContextValue
);

export type FilterContextType = FilterContextValue;

type Props<T, G, M> = {
  pageData?: T;
  allAvailableFilters: AvailableFilters;
  nextAvailableFilters?: AvailableFilters;
  labelsData?: G;
  vehicles?: GenericVehicleDetails[];
  vehiclesOffset?: number;
  vehiclesLimit?: number;
  totalVehiclesCount?: number;
  advisoryContentObject?: CosmicObject;
  seoHeader?: SeoHeaderData;
  headline?: M;
  initialFilter: Filters;
  useQueryParamsOnly?: boolean;
  children: ReactNode;
};

export const FilterProvider = <T, G, M>({
  children,
  pageData,
  allAvailableFilters: ssrAllAvailableFilters,
  nextAvailableFilters: ssrNextAvailableFilters,
  //labelsData,
  vehicles: ssrVehicles,
  totalVehiclesCount: ssrTotalVehiclesCount,
  advisoryContentObject: ssrAdvisoryContentObject = null,
  vehiclesOffset: ssrVehiclesOffset = 0,
  seoHeader,
  headline: ssrHeadlines,
  initialFilter,
  useQueryParamsOnly,
}: Props<T, G, M>) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const filters = useFilterValues();
  const mergeFilters = useMergeFilterValues();
  const allAvailableFilters = useFiltersResponse();

  const {
    nextAvailableFilters,
    totalVehiclesCount,
    isNoIndexNoFollow,
    canonicalUrl,
    advisoryContent,
    breadcrumbs,
    title,
  } = useFilter({
    ssrNextAvailableFilters,
    ssrVehicleData: {
      offset: ssrVehiclesOffset,
      results: ssrVehicles,
      total_results: ssrTotalVehiclesCount,
    },
    ssrAllAvailableFilters,
    ssrAdvisoryContentObject,
    ssrHeadlines,
    pageData,
    initialFilter,
    useQueryParamsOnly,
  });

  const pathname = usePathname();

  useEffect(() => {
    const isForBusinessCookie = getForBusinessCookie();

    if (isForBusinessCookie) {
      mergeFilters({ [FilterKey.IS_FOR_BUSINESS]: true });
    }
  }, []);

  const isForBusinessFilter = filters[FilterKey.IS_FOR_BUSINESS];
  const isInitRef = useRef(false);

  useEffect(() => {
    if (!isInitRef.current) {
      isInitRef.current = true;

      return;
    }

    setForBusinessCookie(isForBusinessFilter ?? false);
  }, [isForBusinessFilter]);

  return (
    <FilterContext.Provider
      value={{
        isNoIndexNoFollow,
        canonicalUrl,
        advisoryContent,
        filterSEOHeader:
          getUpdatedSeoHeader({
            pageData,
            filters,
            totalVehiclesCount,
            allAvailableFilters,
            nextAvailableFilters,
            routerPath: pathname,
          }) || seoHeader,
        breadcrumbs,
        title,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
