import { type IntlShape } from '@finn/ui-utils';
import { slugify } from '@finn/ui-utils/ssr';

import { FilterKey, FilterValuesObject } from '../../core/helpers/filters';
import { DATE_IN_14_DAYS } from '../../core/helpers/path-filter-mapper';
import { type Filters } from '../../filters-management';
import { isElectricVehicle } from '../../product-cards/helpers/vehicle';
import { getSingleSelectItem } from '../helpers/seo';
import {
  getCustomTitleAndSeoHeader,
  getTermUrlName,
} from '../helpers/seo-header';

const booleanFiltersToTitle = {
  [FilterKey.HAS_DEALS]: 'plp.discounts',
  [FilterKey.IS_YOUNG_DRIVER]: 'plp.isYoungDriver',
  [FilterKey.HAS_HITCH]: 'plp.hasHitch',
};

export const getSelectedFilterText = (
  i18n: IntlShape,
  filters: FilterValuesObject,
  filterKey: FilterKey
) => {
  if (Array.isArray(filters[filterKey])) {
    const arrVal = getSingleSelectItem(filters, filterKey);

    if (filterKey === FilterKey.TERMS) {
      return arrVal > 1
        ? `${filters[filterKey]} ${i18n.formatMessage('plp.months')}`
        : i18n.formatMessage('plp.flex');
    }

    return arrVal;
  }

  const text = filters[filterKey];
  if (text && booleanFiltersToTitle[filterKey]) {
    return i18n.formatMessage(booleanFiltersToTitle[filterKey]);
  }

  return text;
};

type GetTitleProps<T> = {
  filters: FilterValuesObject;
  i18n: IntlShape;
  routerPath: string;
  pageData: T;
  isExpPLPReducedFilters?: boolean;
};

export const getTitle = <T>({
  filters,
  i18n,
  routerPath,
  pageData,
  isExpPLPReducedFilters,
}: GetTitleProps<T>) => {
  const customTitle = getCustomTitleAndSeoHeader(routerPath, pageData).title;
  if (customTitle) {
    return customTitle;
  }

  const defaultHeadline = i18n.formatMessage(
    isExpPLPReducedFilters
      ? 'experiments.reducedFiltersV2.defaultTitle'
      : 'plp.defaultTitle'
  );
  const autoAboHeadline = i18n.formatMessage('plp.headline');
  const electricHeadline = i18n.formatMessage('plp.electricTitle');
  const dealsHeadline = i18n.formatMessage('plp.discountsTitle');
  const hitchHeadline = i18n.formatMessage('plp.hitchTitle');
  const youngDriverHeadline = i18n.formatMessage('plp.youngDriverTitle');

  const isElectric =
    filters[FilterKey.FUELS]?.length === 1 &&
    isElectricVehicle(filters[FilterKey.FUELS][0]);

  const numFiltersApplied = Object.keys(filters).length;

  const selectedBrand = getSingleSelectItem(filters, FilterKey.BRANDS);
  const selectedModel = getSingleSelectItem(filters, FilterKey.MODELS);
  const selectedFuelType = getSingleSelectItem(filters, FilterKey.FUELS);
  const selectedCarType = getSingleSelectItem(filters, FilterKey.CAR_TYPES);
  const selectedTerm = getSingleSelectItem(filters, FilterKey.TERMS);
  const selectedDeals = !!filters.has_deals;

  if (selectedBrand && selectedModel) {
    return `${selectedBrand} ${selectedModel} ${autoAboHeadline}`;
  } else if (selectedBrand && selectedFuelType && numFiltersApplied === 2) {
    return `${selectedBrand} ${selectedFuelType} ${autoAboHeadline}`;
  } else if (selectedBrand && selectedCarType && numFiltersApplied === 2) {
    return `${selectedBrand} ${selectedCarType} ${autoAboHeadline}`;
  } else if (selectedBrand) {
    return `${selectedBrand} ${autoAboHeadline}`;
  } else if (isElectric) {
    return electricHeadline;
  } else if (selectedCarType) {
    return `${selectedCarType} ${autoAboHeadline}`;
  } else if (selectedFuelType) {
    return `${selectedFuelType} ${autoAboHeadline}`;
  } else if (filters.has_hitch) {
    return hitchHeadline;
  } else if (filters.is_young_driver) {
    return youngDriverHeadline;
  } else if (selectedTerm) {
    const monthsText = `${selectedTerm} ${i18n.formatMessage('plp.months')}`;
    const flexText = i18n.formatMessage('plp.flex');

    return `${selectedTerm > 1 ? monthsText : flexText} ${autoAboHeadline}`;
  } else if (selectedDeals) {
    return dealsHeadline;
  }

  return defaultHeadline;
};

const getSlugSegment = (filterValue: string[] | undefined): string => {
  return filterValue ? `-${filterValue[0]}` : '';
};

export const isAdvisoryContent = (
  filters: Filters,
  filterKeys: (keyof Filters)[]
): boolean => {
  // Check that each specified key has exactly one item and is an array
  const singleItemFilters = filterKeys.every((key) => {
    const value = filters[key];

    return Array.isArray(value) && value.length === 1;
  });

  // Consider the available_to filter if present without the available_from filter
  const quickAvailabilityFilter =
    Object.keys(filters).includes(FilterKey.AVAILABLE_TO) &&
    !Object.keys(filters).includes(FilterKey.AVAILABLE_FROM);

  // Ensure no extra filters are present beyond what's specified in filterKeys
  const onlySpecifiedFilters = Object.keys(filters).every((key) =>
    filterKeys.includes(key as keyof Filters)
  );

  return (singleItemFilters || quickAvailabilityFilter) && onlySpecifiedFilters;
};

export const getAdvisoryContentSlug = (filters: Filters): string => {
  const slugBase = 'plp-advisory';
  let slugSuffix = '-fallback';

  const FilterKeys = {
    Fuels: 'fuels',
    Terms: 'terms',
    Deals: 'deals',
    Hitch: 'has_hitch',
    YoungDriver: 'is_young_driver',
    Brands: 'brands',
    Models: 'models',
    CarTypes: 'cartypes',
    Gearshifts: 'gearshifts',
    Availability: 'available_to',
  } as const;

  const advisoryChecks = [
    [FilterKeys.Brands, FilterKeys.CarTypes],
    [FilterKeys.Brands, FilterKeys.Models],
    [FilterKeys.Brands, FilterKeys.Fuels],
    [FilterKeys.Brands],
    [FilterKeys.CarTypes],
    [FilterKeys.Gearshifts],
    [FilterKeys.Fuels, FilterKeys.CarTypes],
    [FilterKeys.Fuels],
  ];

  for (const check of advisoryChecks) {
    if (isAdvisoryContent(filters, check)) {
      slugSuffix = check
        .map((key: string | number) => getSlugSegment(filters[key]))
        .join('');
      break;
    }
  }

  // if the deals filter is present, we need to append the deals slug
  if (filters.has_deals) {
    slugSuffix = getSlugSegment([FilterKeys.Deals]);
  }

  if (filters.is_young_driver) {
    slugSuffix = getSlugSegment([FilterKeys.YoungDriver]);
  }

  if (filters.has_hitch) {
    slugSuffix = getSlugSegment([FilterKeys.Hitch]);
  }

  if (slugSuffix === '-fallback') {
    // If the terms filter is present, we need to append the terms slug
    if (isAdvisoryContent(filters, [FilterKeys.Terms])) {
      slugSuffix = `-${getTermUrlName(Number(filters.terms![0]))}`;
    }

    // If the 14-day availability filter is present, we need to append the 14-days slug
    if (isAdvisoryContent(filters, [FilterKeys.Availability])) {
      if (filters[FilterKeys.Availability] === DATE_IN_14_DAYS) {
        slugSuffix = `-14-days`;
      }
    }
  }

  return slugify(`${slugBase}${slugSuffix}`);
};
