import {
  replaceJSRenderTemplate,
  replaceStrKeys,
  slugify,
} from '@finn/ui-utils/ssr';
import get from 'lodash/get';

import { FilterValuesObject } from '../../core';
import { FilterKey } from '../../core/helpers/filters';
import { isSingleSelectItem } from './seo';
import { getInitialFilterKeysMap } from './seo-header';

const getFilterValue = (
  initialFilterKeysMap: ReturnType<typeof getInitialFilterKeysMap>,
  filters: FilterValuesObject,
  key: FilterKey
) => {
  if (!isSingleSelectItem(filters, key)) return '';
  const filterValue = get(filters, `${key}[0]`) as string;
  if (!filterValue) return '';

  return initialFilterKeysMap[slugify(filterValue)]?.displayedName || '';
};

// Replace headline keys data with real values
export const getHeadline = async <T>(
  pageData: T,
  filters: FilterValuesObject,
  initialFilterKeysMap: ReturnType<typeof getInitialFilterKeysMap>
) => {
  const { headlines_templates: templates } = (pageData as any).metadata;
  const titleTemplate = templates.title_template;
  const descriptionTemplate = templates.description_template;

  const defaultTitle = templates?.cars || '';
  const hitchText = templates?.hitch || '';
  const youngDriverText = templates?.young_driver || '';
  const dealsText = templates?.has_deals || '';

  const model = getFilterValue(initialFilterKeysMap, filters, FilterKey.MODELS);
  const brand = getFilterValue(initialFilterKeysMap, filters, FilterKey.BRANDS);
  const cartype = getFilterValue(
    initialFilterKeysMap,
    filters,
    FilterKey.CAR_TYPES
  );
  const fuel = getFilterValue(initialFilterKeysMap, filters, FilterKey.FUELS);
  const gear = getFilterValue(
    initialFilterKeysMap,
    filters,
    FilterKey.GEARSHIFTS
  );
  const color = getFilterValue(initialFilterKeysMap, filters, FilterKey.COLORS);
  const retention = getFilterValue(
    initialFilterKeysMap,
    filters,
    FilterKey.RETENTION
  );
  const hitch = filters.has_hitch ? hitchText : '';
  const youngDriver = filters.has_hitch ? youngDriverText : '';
  const has_deals = filters.has_deals ? dealsText : '';

  const data = {
    model,
    brand,
    cartype,
    fuel,
    gear,
    color,
    hitch,
    retention,
    has_deals,
    youngDriver,
  };

  const title = replaceStrKeys(titleTemplate, data).trim() || defaultTitle;

  const description = await replaceJSRenderTemplate(descriptionTemplate, data);

  return { title, description };
};
