import { getCosmicObjectWithApi } from '@finn/ui-cosmic';
import useSWR from 'swr';

import { Filters } from '../../filters-management';
import { getAdvisoryContentSlug } from '../helpers/content';

export const useAdvisoryContent = (locale: string, filter: Filters) => {
  const advisoryContentCosmicSlug = getAdvisoryContentSlug(filter);
  const hasAdvisoryContent = !advisoryContentCosmicSlug.includes('fallback');

  return useSWR(
    hasAdvisoryContent && {
      slug: advisoryContentCosmicSlug,
      showMetafields: true,
      locale,
    },
    getCosmicObjectWithApi
  );
};
