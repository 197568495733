import { Breadcrumb } from '@finn/ua-auth';
import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { Features, useIsABVariant, useIsCVariant } from '@finn/ua-featureflags';
import {
  IntlShape,
  isMobileApp,
  useCurrentLocale,
  useIntl,
} from '@finn/ui-utils';
import trimEnd from 'lodash/trimEnd';
import { useMemo } from 'react';
import useSWR from 'swr';

import {
  getHeadline,
  getInitialFilterKeysMap,
  getSelectedFilterText,
  getTitle,
} from '../../content';
import {
  FilterKey,
  FiltersResponse,
  FilterValuesObject,
  serializeFiltersToPath,
} from '../../core';
import { AvailableFilters } from './useAvailableFilters';
import { useFiltersResponse } from './useFilterResponse';

// Breadcrumbs will be shown the reverse order of this array
// because we are adding them to the beginning of the array
// which makes it easier to exclude filters from the URLs
const FILTERS_BREADCRUMBS = [
  FilterKey.HAS_HITCH,
  FilterKey.IS_YOUNG_DRIVER,
  FilterKey.HAS_DEALS,
  FilterKey.RETENTION,
  FilterKey.TERMS,
  FilterKey.GEARSHIFTS,
  FilterKey.FUELS,
  FilterKey.CAR_TYPES,
  FilterKey.MODELS,
  FilterKey.BRANDS,
];

type GetBreadcrumbs = {
  filters: FilterValuesObject;
  allAvailableFilters: FiltersResponse;
  i18n: IntlShape;
  locale: string;
};

const addFilterBreadcrumb = (
  breadcrumbs: Breadcrumb[],
  selectedFilter: string | boolean,
  filtersToExclude: object,
  filters: FilterValuesObject,
  intl: IntlShape,
  allAvailableFilters: FiltersResponse,
  defaultValue = undefined
): Breadcrumb[] => {
  const breadcrumbsArray = breadcrumbs;
  if (selectedFilter) {
    const filterURL =
      breadcrumbsArray.length > 0
        ? serializeFiltersToPath(
            { ...filters, ...filtersToExclude },
            BASE_SUBSCRIPTION_PATH,
            'de-DE',
            allAvailableFilters
          )
        : undefined;
    breadcrumbsArray.unshift({
      title: defaultValue ?? (selectedFilter as string),
      url: trimEnd(filterURL && `/de-DE${filterURL}`, '/'),
    });
  }

  return breadcrumbsArray;
};

export const getBreadcrumbs = ({
  filters,
  allAvailableFilters,
  i18n,
  locale,
}: GetBreadcrumbs) => {
  const defaultHeadline = i18n.formatMessage('plp.defaultTitle');
  const breadcrumbs = [];

  const addedFilters = {};
  for (const filterKey of FILTERS_BREADCRUMBS) {
    const selectedFilter = getSelectedFilterText(i18n, filters, filterKey);
    if (!selectedFilter) {
      continue;
    }

    addFilterBreadcrumb(
      breadcrumbs,
      selectedFilter,
      addedFilters,
      filters,
      i18n,
      allAvailableFilters
    );
    addedFilters[filterKey] = null;
  }

  breadcrumbs.unshift({
    title: defaultHeadline,
    url: `/${locale}/${BASE_SUBSCRIPTION_PATH}`,
  });

  return breadcrumbs;
};

export const useBreadcrumbs = <T>({
  ssrAllAvailableFilters,
  pageData,
  filters,
  routerPath,
}: {
  ssrAllAvailableFilters: AvailableFilters;
  pageData: T;
  filters: FilterValuesObject;
  routerPath: string;
}) => {
  const i18n = useIntl();
  const initialFilterKeysMap = getInitialFilterKeysMap(ssrAllAvailableFilters);
  const allAvailableFilters = useFiltersResponse();
  const { locale } = useCurrentLocale();
  const isApp = isMobileApp();
  const isExpPLPReducedFiltersB = useIsABVariant(Features.ExpPLPReducedFilters);
  const isExpPLPReducedFiltersC = useIsCVariant(Features.ExpPLPReducedFilters);

  const breadcrumbs = useMemo(() => {
    return getBreadcrumbs({
      filters,
      allAvailableFilters,
      i18n,
      locale,
    });
  }, [filters, allAvailableFilters, i18n, locale]);

  const title = getTitle({
    filters,
    i18n,
    routerPath,
    pageData,
    isExpPLPReducedFilters:
      (isExpPLPReducedFiltersB || isExpPLPReducedFiltersC) && !isApp,
  });

  const { data: headline, isLoading } = useSWR(
    [pageData, filters, initialFilterKeysMap],
    getHeadline as any
  );

  return {
    headline,
    breadcrumbs,
    title,
    isLoading,
  };
};
