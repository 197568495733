import { CosmicObject } from '@finn/ui-cosmic';
import { config, isServer, useCurrentLocale } from '@finn/ui-utils';
import { usePathname } from 'next/navigation';
import { useMemo, useState } from 'react';

import { useAdvisoryContent } from '../../content';
import { FiltersResponse, GetVehiclesResponse } from '../../core';
import { useIsOutOfStock } from '../../product-listing';
import { useFilterValues } from '../data/lib';
import { getNoIndexNoFollow } from '../helpers/filter-helpers';
import { AvailableFilters, useAvailableFilters } from './useAvailableFilters';
import { useBreadcrumbs } from './useBreadcrumbs';
import { Filters } from './useInitialFilterValues';
import { useResultsCount } from './useResultsCount';

type UserFilterProps<T, G> = {
  ssrAllAvailableFilters?: FiltersResponse;
  ssrNextAvailableFilters?: FiltersResponse;
  ssrVehicleData?: GetVehiclesResponse;
  ssrAdvisoryContentObject?: CosmicObject | null;
  ssrHeadlines?: T;
  pageData?: G;
  initialFilter?: Filters;
  useQueryParamsOnly?: boolean;
};

/**
 * TODO
 * As a lot of our filter logic depends on "exact" path, instead of the one provided by router.pathname
 * we may have a case after which some special "slug" can appear in the begining of the page url and mess things app.
 * But before we migrated to pathname usage, we use this additional filtering option.
 * For now we are having only "/mobile/subscribe/**" case
 */
// const filterOutHiddenSlugs = (str: string) => !str.includes('mobile');

export const useFilter = <T, G>({
  ssrAllAvailableFilters,
  ssrNextAvailableFilters,
  ssrAdvisoryContentObject,
  ssrVehicleData,
  ssrHeadlines,
  pageData,
}: UserFilterProps<T, G>) => {
  const pathname = usePathname();
  const { locale } = useCurrentLocale();
  const [allAvailableFilters] = useState<AvailableFilters>(
    ssrAllAvailableFilters
  );

  const isOutOfStockPage = useIsOutOfStock();
  const filterValues = useFilterValues();
  const nextAvailableFilters = useAvailableFilters({
    ssrFilters: ssrNextAvailableFilters,
    isOutOfStockPage,
  });

  const { data: advisoryContent, isLoading: avisoryContentLoading } =
    useAdvisoryContent(locale, filterValues as unknown as Filters);
  const {
    headline,
    breadcrumbs,
    title,
    isLoading: breadcrumbsLoading,
  } = useBreadcrumbs({
    ssrAllAvailableFilters,
    filters: filterValues,
    routerPath: pathname,
    pageData,
  });

  const isLoading = avisoryContentLoading || breadcrumbsLoading;

  const totalVehiclesCount =
    useResultsCount(filterValues) || ssrVehicleData.total_results;

  const [isNoIndexNoFollow, canonicalUrl] = useMemo(() => {
    if (isServer()) return [false, '']; // return value doesn't matter, these values are not used on SSR
    const url = `${config.FINN_WEB_URL}/${locale}${pathname.split('?')[0]}`;

    return [
      getNoIndexNoFollow(filterValues, pathname, totalVehiclesCount),
      url,
    ];
  }, [locale, pathname, filterValues]);

  return {
    isLoading,
    filters: filterValues,
    nextAvailableFilters,
    allAvailableFilters,
    isNoIndexNoFollow,
    canonicalUrl,
    advisoryContent: advisoryContent ?? ssrAdvisoryContentObject,
    headline: headline ?? ssrHeadlines,
    breadcrumbs,
    totalVehiclesCount,
    title,
  };
};
