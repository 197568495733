import { useCurrentLocale } from '@finn/ui-utils';
import { useRef } from 'react';

import {
  Brand,
  Color,
  FiltersResponse,
  FilterValuesObject,
  useFetchFilters,
} from '../../core';
import { useDiscoveryConfig } from '../../core/data/MetadataProvider';
import { useFilterValues } from '../data/lib';
import { VehicleView } from '../helpers/filter-helpers';
import { useFiltersResponse } from './useFilterResponse';

type UseAvailableFilters = {
  filters?: FilterValuesObject;
  isOutOfStockPage?: boolean;
  ssrFilters?: FiltersResponse;
};

export type AvailabilityMap = {
  [date: string]: { startDate: string; endDate: string };
};

export type AvailableFilters = {
  availability: string[];
  brands: Brand[];
  cartypes: string[];
  models: string[];
  gearshifts: string[];
  terms: number[];
  fuels: string[];
  colors: Color[];
  has_hitch: boolean[];
  is_young_driver: boolean[];
  min_price: number;
  max_price: number;
  min_price_msrp: number;
  max_price_msrp: number;
  min_power: number;
  max_power: number;
  is_for_business: boolean[];
  view?: VehicleView;
  total_results: number;
};

export const applyPolicy = (
  policy: ReturnType<typeof useDiscoveryConfig>['carPolicy'],
  filters: FiltersResponse
) => {
  if (!policy) {
    return filters;
  }

  if (policy.brands) {
    filters.brands = filters.brands?.filter((brand) =>
      policy.brands.includes(brand.id)
    );
  }
  if (policy.fuelTypes) {
    filters.fuels = filters.fuels?.filter((fuel) =>
      policy.fuelTypes.includes(fuel)
    );
  }
  if (policy.carTypes) {
    filters.cartypes = filters.cartypes?.filter((cartype) =>
      policy.carTypes.includes(cartype)
    );
  }
  if (policy.gearshifts) {
    filters.gearshifts = filters.gearshifts?.filter((gearshift) =>
      policy.gearshifts.includes(gearshift)
    );
  }
};

/**
 * Returns the next set of filters you could apply, based on already applied filters. (eg. only type SUV when filtering for Humvee)
 *
 * @param filtersObject Object containing currently filters applied in the client and when initially loading the page (ssr)
 * @returns The next set of filters that can be applied
 */
export const useAvailableFilters = (
  filtersObject: UseAvailableFilters
): FiltersResponse => {
  const { locale } = useCurrentLocale();
  const filterValues = useFilterValues();
  const filterResponse = useFiltersResponse();
  const responseRef = useRef<FiltersResponse>(null);
  const config = useDiscoveryConfig();

  const filters = filtersObject?.filters;
  const ssrFilters = filtersObject?.ssrFilters;

  const selectedFilters = filters || filterValues || {};

  const { data: nextFilterResponse } = useFetchFilters(
    {
      filters: { ...selectedFilters }, // need to spread to create a copy otherwise a bug appears
      locale,
      zipCode: undefined,
      hide_related: true,
    },
    filtersObject?.isOutOfStockPage
  );

  responseRef.current =
    nextFilterResponse ?? ssrFilters ?? responseRef.current ?? filterResponse;

  if (config.carPolicy) {
    applyPolicy(config.carPolicy, responseRef.current);
  }

  return responseRef.current!;
};
